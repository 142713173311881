import { ref, reactive  } from 'vue';
import { $loading} from '@/api/index';
import { toast } from '@/utils/toast';
import router from '@/router/index';
import { Loading } from '@/utils/loading';
import { loadingController } from '@ionic/vue';

export interface Resiter{
  id: string;
  company: string;
  name: string;
  tlno: string;
  email: string;
  sano: string;
  userGubn: string;
  manager: string;
}
export function users() {
  const isConnected = ref(true);
  const apply = reactive({
    id:'',
    password: '',
    company: '',
    priv: '',
    tlno: '',
    email:'',
    sano: '',
    userGubn: '',
    manager: ''
  });

  async function insertApply() {
    const loading = new Loading(loadingController);
    loading.present('회원가입 요청중...');  
    await $loading(`/register`,'post', apply).then((res: any) => {
      
      if (res.status == 200) {    
        loading.dismiss();
        toast('등록완료'+'\n'+'승인이 완료된 후 로그인할 수 있습니다.', 5000);      
        router.replace('/login');
      }
    }).catch(err => {
        loading.dismiss();
        if (err.response.status == 406) {   
          alert(err.response.data.message);
        } else if (err.response.status == 500) {
          toast(
            '내부서버의 오류로 인해 현재 서비스의 상태가 원할하지 않습니다. 시스템 관리자에게 문의하시기 바랍니다.',
            5000
          );
        }
      });
  }


  return { isConnected, insertApply, apply};
}

